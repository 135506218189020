import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Encode Sans Expanded', sans-serif;
  }

  ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    }


  ::-webkit-scrollbar-track{
  background-color: #F2F2F2;

  }

  ::-webkit-scrollbar-thumb{
  background-color: #f74b2d;
  border-radius: 30px;
  }

  
`;
