import styled from "styled-components";
import { cores } from "../../themes/colors";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";

export const Main = styled.div`
  background-color: ${cores.lightBackground};
  padding-bottom: 64px;
`;

export const ContainerTitulo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${cores.lightBackground};
  padding-bottom: 40px;
`;

export const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${cores.lightBackground};
  overflow-x: scroll;
  @media screen and (max-width: 1100px) {
    align-items: flex-start;
    margin-left: 40px;
  }
  @media screen and (max-width: 340px) {
    align-items: flex-start;
    margin-left: 10px;
  }
`;

export const NavGrid = styled.div`
  position: sticky;
  border-bottom: 1px solid ${cores.secund};
  background-color: ${cores.lightBackground};
  margin-bottom: 20px;
`;

export const SubText = styled.p`
  text-align: center;
  margin-top: -40px;
  font-size: 18px;
`;

export const ContainerGrid = styled.div`
  width: 1100px;
`;

export const Item = styled.div`
  padding: 20px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 60px;

  h6 {
    font-weight: 700;
    font-size: 1rem;
    @media screen and (max-width: 960px) {
      font-size: 0.8rem;
    }
  }
  p {
    font-weight: 600;
    font-size: 0.8rem;
    @media screen and (max-width: 960px) {
      font-size: 0.7rem;
    }
  }
`;

export const ItemHeader = styled(Item)`
  justify-content: center;
`;

export const ItemIndex = styled(Item)`
  justify-content: flex-start;
`;

export const BoxCheck = styled(Item)`
  height: 60px;
  width: 100%;
`;

export const CheckIcon = styled(AiOutlineCheckCircle)`
  color: ${cores.secund};
  font-size: 1rem;
`;

export const NotIcon = styled(AiOutlineClose)`
  color: ${cores.secund};
  font-size: 1rem;
`;
