export const infoPlano = {
  basico: {
    nome: "básico",
    modulos: [
      {
        descricao:
          "ASSESSORIA EM RH - Assessoria nos processos de desenvolvimento humano, visando a melhoria dos resultados corporativos.",
        checked: true,
      },
      {
        descricao:
          "IMPLANTAÇÃO DE METODOLOGIA DE TRABALHO - Implantação, organização e planejamento estratégico dos subsistemas de RH.",
        checked: true,
      },
      {
        descricao:
          "PROCESSO SELETIVO TECNOLÓGICO Desenvolver processo profissional de recrutamento e seleção estratégicos e tecnológico.",
        checked: false,
      },
      {
        descricao: "RECRUTAMENTO E SELEÇÃO",
        quantidade: "a negociar",
        checked: true,
      },
      {
        descricao:
          "PROGRAMA DE DESENVOLVIMENTO PROFISSIONAL - Desenvolver programa de treinamento e desenvolvimento, individual e coletivo, de acordo com os objetivos estratégicos da organização.",
        checked: false,
      },
      {
        descricao:
          "DESENVOLVIMENTO DE LIDERANÇAS - Desenvolver nas lideranças, habilidades em gestão de pessoas.",
        checked: true,
      },
      {
        descricao:
          "TREINAMENTO E DESEVOLVIMENTO - Para todo treinamento, uma negociação será diferenciada.",
        quantidade: 0,
        horas: 0,
        checked: { valido: true, quantidade: "0", horas: "0" },
      },
      {
        descricao:
          "NORMAS E PROCEDIMENTOS - Criação de normas e procedimentos administrativos de gestão de pessoas.",
        checked: true,
      },
      {
        descricao:
          "PERFIL PESSOAL E PROFISSIONAL - Desenvolvimento de perfil profissional e comportamental para cada cargo.",
        checked: false,
      },
      {
        descricao:
          "CULTURA ORGANIZACIONAL - Defender, propagar e alinhar a cultura organizacional.",
        checked: true,
      },
      {
        descricao:
          "CLIMA ORGANIZACIONAL - Zelar pelo bom clima organizacional.",
        checked: true,
      },
      {
        descricao:
          "RELACIONAMENTO INTERPESSOAL - Reduzir conflitos entre colaboradores.",
        checked: true,
      },
      {
        descricao:
          "QUALIDADE DE VIDA - Monitorar e assegurar melhor qualidade de vida dos envolvidos",
        checked: true,
      },
      {
        descricao: "COMPROMETIMENTO - Engajar pessoas e equipes de trabalho.",
        checked: true,
      },
      {
        descricao:
          "MERITOCRACIA - Criar condições de crescimento por merecimento.",
        checked: true,
      },
      {
        descricao:
          "GESTÃO DE DESEMPENHO - Aplicar gestão visando transformar potencial em desempenho.",
        checked: false,
      },
      {
        descricao:
          "TRANSPARÊNCIA - Promover colaboradores de forma transparente, eliminando os ruídos",
        checked: true,
      },
      {
        descricao: "MOTIVAÇÃO - Motivar os colaboradores.",
        checked: true,
      },
    ],
  },
  essencial: {
    nome: "essencial",
    modulos: [
      {
        descricao:
          "ASSESSORIA EM RH - Assessoria nos processos de desenvolvimento humano, visando a melhoria dos resultados corporativos.",
        checked: true,
      },
      {
        descricao:
          "IMPLANTAÇÃO DE METODOLOGIA DE TRABALHO - Implantação, organização e planejamento estratégico dos subsistemas de RH.",
        checked: true,
      },
      {
        descricao:
          "PROCESSO SELETIVO TECNOLÓGICO Desenvolver processo profissional de recrutamento e seleção estratégicos e tecnológico.",
        checked: true,
      },
      {
        descricao: "RECRUTAMENTO E SELEÇÃO",
        quantidade: "5/ano",
        checked: { valido: true, quantidade: 10 },
      },
      {
        descricao:
          "PROGRAMA DE DESENVOLVIMENTO PROFISSIONAL - Desenvolver programa de treinamento e desenvolvimento, individual e coletivo, de acordo com os objetivos estratégicos da organização.",
        checked: true,
      },
      {
        descricao:
          "DESENVOLVIMENTO DE LIDERANÇAS - Desenvolver nas lideranças, habilidades em gestão de pessoas.",
        checked: true,
      },
      {
        descricao:
          "TREINAMENTO E DESEVOLVIMENTO - Para todo treinamento, uma negociação será diferenciada.",
        quantidade: "20 Horas",
        horas: 20,
        checked: { valido: true, quantidade: 5, horas: 20 },
      },
      {
        descricao:
          "NORMAS E PROCEDIMENTOS - Criação de normas e procedimentos administrativos de gestão de pessoas.",
        checked: true,
      },
      {
        descricao:
          "PERFIL PESSOAL E PROFISSIONAL - Desenvolvimento de perfil profissional e comportamental para cada cargo.",
        checked: true,
      },
      {
        descricao:
          "CULTURA ORGANIZACIONAL - Defender, propagar e alinhar a cultura organizacional.",
        checked: true,
      },
      {
        descricao:
          "CLIMA ORGANIZACIONAL - Zelar pelo bom clima organizacional.",
        checked: true,
      },
      {
        descricao:
          "RELACIONAMENTO INTERPESSOAL - Reduzir conflitos entre colaboradores.",
        checked: true,
      },
      {
        descricao:
          "QUALIDADE DE VIDA - Monitorar e assegurar melhor qualidade de vida dos envolvidos",
        checked: true,
      },
      {
        descricao: "COMPROMETIMENTO - Engajar pessoas e equipes de trabalho.",
        checked: true,
      },
      {
        descricao:
          "MERITOCRACIA - Criar condições de crescimento por merecimento.",
        checked: true,
      },
      {
        descricao:
          "GESTÃO DE DESEMPENHO - Aplicar gestão visando transformar potencial em desempenho.",
        checked: true,
      },
      {
        descricao:
          "TRANSPARÊNCIA - Promover colaboradores de forma transparente, eliminando os ruídos",
        checked: true,
      },
      {
        descricao: "MOTIVAÇÃO - Motivar os colaboradores.",
        checked: true,
      },
    ],
  },
  intermediario: {
    nome: "Intermediário",
    modulos: [
      {
        descricao:
          "ASSESSORIA EM RH - Assessoria nos processos de desenvolvimento humano, visando a melhoria dos resultados corporativos.",
        checked: true,
      },
      {
        descricao:
          "IMPLANTAÇÃO DE METODOLOGIA DE TRABALHO - Implantação, organização e planejamento estratégico dos subsistemas de RH.",
        checked: true,
      },
      {
        descricao:
          "PROCESSO SELETIVO TECNOLÓGICO Desenvolver processo profissional de recrutamento e seleção estratégicos e tecnológico.",
        checked: true,
      },
      {
        descricao: "RECRUTAMENTO E SELEÇÃO",
        quantidade: "10/ano",
        checked: { valido: true, quantidade: 10 },
      },
      {
        descricao:
          "PROGRAMA DE DESENVOLVIMENTO PROFISSIONAL - Desenvolver programa de treinamento e desenvolvimento, individual e coletivo, de acordo com os objetivos estratégicos da organização.",
        checked: true,
      },
      {
        descricao:
          "DESENVOLVIMENTO DE LIDERANÇAS - Desenvolver nas lideranças, habilidades em gestão de pessoas.",
        checked: true,
      },
      {
        descricao:
          "TREINAMENTO E DESEVOLVIMENTO - Para todo treinamento, uma negociação será diferenciada.",
        quantidade: "40 Horas",
        horas: 40,
        checked: { valido: true, quantidade: 10 },
      },
      {
        descricao:
          "NORMAS E PROCEDIMENTOS - Criação de normas e procedimentos administrativos de gestão de pessoas.",
        checked: true,
      },
      {
        descricao:
          "PERFIL PESSOAL E PROFISSIONAL - Desenvolvimento de perfil profissional e comportamental para cada cargo.",
        checked: true,
      },
      {
        descricao:
          "CULTURA ORGANIZACIONAL - Defender, propagar e alinhar a cultura organizacional.",
        checked: true,
      },
      {
        descricao:
          "CLIMA ORGANIZACIONAL - Zelar pelo bom clima organizacional.",
        checked: true,
      },
      {
        descricao:
          "RELACIONAMENTO INTERPESSOAL - Reduzir conflitos entre colaboradores.",
        checked: true,
      },
      {
        descricao:
          "QUALIDADE DE VIDA - Monitorar e assegurar melhor qualidade de vida dos envolvidos",
        checked: true,
      },
      {
        descricao: "COMPROMETIMENTO - Engajar pessoas e equipes de trabalho.",
        checked: true,
      },
      {
        descricao:
          "MERITOCRACIA - Criar condições de crescimento por merecimento.",
        checked: true,
      },
      {
        descricao:
          "GESTÃO DE DESEMPENHO - Aplicar gestão visando transformar potencial em desempenho.",
        checked: true,
      },
      {
        descricao:
          "TRANSPARÊNCIA - Promover colaboradores de forma transparente, eliminando os ruídos",
        checked: true,
      },
      {
        descricao: "MOTIVAÇÃO - Motivar os colaboradores.",
        checked: true,
      },
    ],
  },
  premium: {
    nome: "premium",
    modulos: [
      {
        descricao: "ASSESSORIA EM RH ",
        checked: true,
      },
      {
        descricao: "IMPLANTAÇÃO DE METODOLOGIA DE TRABALHO",
        checked: true,
      },
      {
        descricao: "PROCESSO SELETIVO TECNOLÓGICO",
        checked: true,
      },
      {
        descricao: "RECRUTAMENTO E SELEÇÃO",
        quantidade: "Ilimitado",
        checked: { valido: true, quantidade: 15 },
      },
      {
        descricao: "PROGRAMA DE DESENVOLVIMENTO PROFISSIONAL",
        checked: true,
      },
      {
        descricao: "DESENVOLVIMENTO DE LIDERANÇAS",
        checked: true,
      },
      {
        descricao: "TREINAMENTO E DESEVOLVIMENTO",
        quantidade: "60 Horas",
        horas: 60,
        checked: { valido: true, quantidade: 15 },
      },
      {
        descricao: "NORMAS E PROCEDIMENTOS",
        checked: true,
      },
      {
        descricao: "PERFIL PESSOAL E PROFISSIONAL",
        checked: true,
      },
      {
        descricao: "CULTURA ORGANIZACIONAL",
        checked: true,
      },
      {
        descricao: "CLIMA ORGANIZACIONAL",
        checked: true,
      },
      {
        descricao: "RELACIONAMENTO INTERPESSOAL",
        checked: true,
      },
      {
        descricao: "QUALIDADE DE VIDA",
        checked: true,
      },
      {
        descricao: "COMPROMETIMENTO",
        checked: true,
      },
      {
        descricao: "MERITOCRACIA",
        checked: true,
      },
      {
        descricao: "GESTÃO DE DESEMPENHO.",
        checked: true,
      },
      {
        descricao: "TRANSPARÊNCIA",
        checked: true,
      },
      {
        descricao: "MOTIVAÇÃO",
        checked: true,
      },
    ],
  },
};
