import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./paginas";
import { GlobalStyle } from "./components/GlobalStyle";

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
        </Switch>
      </Router>
    </>
  );
}

export default App;
