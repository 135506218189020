import styled from "styled-components";
import { cores } from "../../themes/colors";

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
  max-width: 380px;

  @media screen and (max-width: 400px) {
    margin: 20px;
  }
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${cores.secund};
  height: 280px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 40px;

  @media screen and (max-width: 400px) {
    margin-bottom: 20px;
    padding: 20px;
  }
`;

export const BoxNome = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 400px) {
    margin: 0 20px;
    padding: 20px;
  }

  p {
    color: ${cores.darkText};
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export const BoxDescricao = styled.div`
  margin-top: 20px;
  @media screen and (max-width: 400px) {
    margin: 0 20px;
    padding: 20px;
  }

  p {
    color: ${cores.darkText};
    font-size: 18px;
    color: ${cores.darkText};
    line-height: 24px;
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
`;

export const VantagemIcon = styled.img`
  width: 100%;
`;
