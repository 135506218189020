import EscalabilidadeImage from "../../assets/aumentar.svg";
import DecisaoImage from "../../assets/decisao.svg";
import PrecoImage from "../../assets/preco.svg";
import ClienteImage from "../../assets/cliente.svg";

export const infoVantagens = [
  {
    id: "1",
    nome: "Concentração no seu negócio",
    descricao:
      "Maior concentração no negócio da sua empresa sem ter que se preocupar com ambiente de trabalho.",
    icon: DecisaoImage,
  },
  {
    id: "2",
    nome: "Escalabilidade",
    descricao:
      "Se preocupe apenas em aumentar o seu negócio, produtos, possibilidades.",
    icon: EscalabilidadeImage,
  },
  {
    id: "3",
    nome: "Maior foco no cliente",
    descricao:
      "Centralize seu foco em conquistar mais clientes e em gerenciar os clientes já ativos.",
    icon: ClienteImage,
  },
  {
    id: "13",
    nome: "Redução de custos",
    descricao:
      "Diminua o custo de pessoal, encargos trabalhistas, infraestrutura entre outros.",
    icon: PrecoImage,
  },
];
