import React, { useState } from "react";
import video from "../../videos/video-2.mp4";
import { Button } from "../Button";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./styles";
import { linkWhatApp } from "../../services/linkWhatsApp";

const MainComponent = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>Estratégias para engajar pessoas</HeroH1>
        <HeroP>
          O nosso negócio é facilitar esta relação entre as pessoas e a
          organização. É criar condições favoráveis para que cada um entregue o
          melhor de si, contribuindo para transformar potencial em desempenho,
          sonhos em ações e visão em realidade. Nós conhecemos o caminho!
        </HeroP>
        <HeroBtnWrapper>
          <Button
            href={linkWhatApp}
            target="_blank"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Realizar Contato {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default MainComponent;
