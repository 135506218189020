import { Titulo } from "../Titulo";
import {
  BoxCheck,
  CheckIcon,
  Container,
  ContainerGrid,
  ContainerTitulo,
  ItemHeader,
  ItemIndex,
  Main,
  NavGrid,
  NotIcon,
  SubText,
} from "./styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { infoPlano } from "./infoPlano";

export default function Planos() {
  function validaItem({ quantidade, checked }) {
    if (typeof checked === "boolean") {
      return checked ? <CheckIcon /> : <NotIcon />;
    } else {
      return quantidade ? <p>{quantidade}</p> : <NotIcon />;
    }
  }

  return (
    <Main id="planos">
      <Container>
        <ContainerGrid>
          <Box sx={{ width: "100%" }}>
            <NavGrid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1 }}
                columns={11}
              >
                <Grid item xs={3}>
                  <ItemHeader>
                    <h6>Modulo</h6>
                  </ItemHeader>
                </Grid>
                <Grid item xs={2}>
                  <ItemHeader>
                    <h6>Básico</h6>
                  </ItemHeader>
                </Grid>
                <Grid item xs={2}>
                  <ItemHeader>
                    <h6>Essencial</h6>
                  </ItemHeader>
                </Grid>
                <Grid item xs={2}>
                  <ItemHeader>
                    <h6>Intermediário</h6>
                  </ItemHeader>
                </Grid>
                <Grid item xs={2}>
                  <ItemHeader>
                    <h6>Premium</h6>
                  </ItemHeader>
                </Grid>
              </Grid>
            </NavGrid>
            <Grid container columns={11} columnSpacing={{ xs: 1 }}>
              <Grid item xs={3}>
                {infoPlano.premium.modulos.map((item, key) => {
                  const descricao =
                    item.descricao[0].toUpperCase() +
                    item.descricao.substring(1).toLocaleLowerCase();
                  return (
                    <ItemIndex key={key}>
                      <p>{descricao}</p>
                    </ItemIndex>
                  );
                })}
              </Grid>
              <Grid item xs={2}>
                {infoPlano.basico.modulos.map((item, key) => {
                  return <BoxCheck key={key}>{validaItem(item)}</BoxCheck>;
                })}
              </Grid>
              <Grid item xs={2}>
                {infoPlano.essencial.modulos.map((item, key) => {
                  return <BoxCheck key={key}>{validaItem(item)}</BoxCheck>;
                })}
              </Grid>
              <Grid item xs={2}>
                {infoPlano.intermediario.modulos.map((item, key) => {
                  return <BoxCheck key={key}>{validaItem(item)}</BoxCheck>;
                })}
              </Grid>
              <Grid item xs={2}>
                {infoPlano.premium.modulos.map((item, key) => {
                  return <BoxCheck key={key}>{validaItem(item)}</BoxCheck>;
                })}
              </Grid>
            </Grid>
          </Box>
        </ContainerGrid>
      </Container>
    </Main>
  );
}

export const BoxTitulo = () => {
  return (
    <ContainerTitulo>
      <Titulo black={true ? 1 : 0}>Nossos Planos</Titulo>
      <SubText>Comece com o plano perfeito para a sua empresa.</SubText>
    </ContainerTitulo>
  );
};
