import {
  BoxDescricao,
  BoxNome,
  BoxIcon,
  ContainerCard,
  VantagemIcon,
} from "./styles";

export default function CardVantagens({ icon, nome, descricao }) {
  return (
    <ContainerCard>
      <BoxIcon>
        <VantagemIcon src={icon} />
      </BoxIcon>

      <BoxNome>
        <p>{nome}</p>
      </BoxNome>

      <BoxDescricao>
        <p>{descricao}</p>
      </BoxDescricao>
    </ContainerCard>
  );
}
