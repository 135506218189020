import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainter,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebSiteRights,
} from "./styles";
import { BoxLogo } from "../NavBar/styles";
import Logo from "../../assets/logo.svg";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainter>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <BoxLogo>
                <img src={Logo} alt="Logo" />
              </BoxLogo>
            </SocialLogo>
            <WebSiteRights>
              Heroup ₢ {new Date().getFullYear()} Todos os direitos reservados.
            </WebSiteRights>
            <WebSiteRights>CNPJ - 38.093.708/0001-07</WebSiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainter>
  );
};

export default Footer;
