import styled from "styled-components";
import { cores } from "../../themes/colors";

export const ServicesContainer = styled.div`
  height: auto;
  display: ${({ display }) => (display ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  overflow: hidden;
  padding: 0 24px 40px 24px;
`;

export const ServicesH1 = styled.h3`
  font-size: 2.1rem;
  color: ${cores.secund};
  margin: 40px 0;

  @media screen and (max-width: 468px) {
    font-size: 2rem;
  }
`;

export const BoxCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 1000px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
