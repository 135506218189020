import {
  ServicesCards,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./styles.js";

export const CardServico = ({ icon, titulo, descricao }) => {
  const tituloFormatado =
    titulo[0]?.toUpperCase() + titulo?.substring(1).toLowerCase();

  return (
    <ServicesCards>
      <ServicesIcon src={icon} />
      <div>
        <ServicesH2>{tituloFormatado}</ServicesH2>
      </div>
      <ServicesP>{descricao}</ServicesP>
    </ServicesCards>
  );
};

export default CardServico;
