import React from "react";
import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarWrapper,
  SideBarMenu,
  SideBarLink,
  SideBtnWrap,
  SideBarRoute,
} from "./styles";
import { linkWhatApp } from "../../services/linkWhatsApp";

const SideBar = ({ isOpen, toggle }) => {
  return (
    <SideBarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SideBarWrapper>
        <SideBarMenu>
          <SideBarLink to="objetivo" onClick={toggle}>
            Vantagens
          </SideBarLink>
          <SideBarLink to="objetivo" onClick={toggle}>
            Objetivo
          </SideBarLink>
          <SideBarLink to="sobre" onClick={toggle}>
            Sobre
          </SideBarLink>
          <SideBarLink to="services" onClick={toggle}>
            Serviços
          </SideBarLink>
          <SideBarLink to="planos" onClick={toggle}>
            Nossos Planos
          </SideBarLink>
        </SideBarMenu>
        <SideBtnWrap>
          <SideBarRoute
            href={linkWhatApp}
            target="_blank"
          >
            Contato
          </SideBarRoute>
        </SideBtnWrap>
      </SideBarWrapper>
    </SideBarContainer>
  );
};

export default SideBar;
