import Icon1 from "../../assets/svg-1.svg";
import ImplantacaoImage from "../../assets/svg-2.svg";
import RecrutamentoImage from "../../assets/svg-3.svg";
import coachingImage from "../../assets/coaching.svg";
import ProfessorImage from "../../assets/professor.svg";
import DesenvolvimentoEquipeImage from "../../assets/desenvolvimento_equipe.svg";
import LiderImage from "../../assets/lider.svg";
import ProcedimentoImage from "../../assets/procedimentos.svg";
import PessoasImage from "../../assets/people.svg";
import ComportamentoImage from "../../assets/comportamento.svg";
import ClimaImage from "../../assets/clima.svg";
import CulturaImage from "../../assets/cultura.svg";
import QualidadeImage from "../../assets/qualidade.svg";
import RelacaoPessoalImage from "../../assets/relacao_pessoal.svg";
import DesempenhoImage from "../../assets/desempenho.svg";
import MotivacaoImage from "../../assets/motivacao.svg";

export const infoServicos = [
  {
    id: "7",
    titulo: "Recrutamento e Seleção",
    descricao:
      "Recrutar e selecionar pessoas, visando a melhoria da entrega os resultados organizacionais.",
    icon: RecrutamentoImage,
  },
  {
    id: "1",
    titulo: "Treinamento",
    descricao:
      "Desenvolvimento profissional dos colaboradores com alinhamento dos objetivos estratégicos da organização",
    icon: coachingImage,
  },
  {
    id: "3",
    titulo: "Formação Profissional",
    descricao: "Criação de cursos personalizados para seu negocio.",
    icon: ProfessorImage,
  },
  {
    id: "4",
    titulo: "Acessoria em Recursos Humanos",
    descricao:
      "Assessoria nos processos de desenvolvimento humano, visando a melhorias dos resultados corporativos.",
    icon: Icon1,
  },
  {
    id: "5",
    titulo: "Implantação de Metodologia",
    descricao:
      "Implantação, organização e planejamento estratégico dos subsistemas de RH. ",
    icon: ImplantacaoImage,
  },
  {
    id: "6",
    titulo: "Desenvolvimento de equipe.",
    descricao:
      "Desenvolver processo profissional de recrutamento e seleção estratégicos e tecnológico. ",
    icon: DesenvolvimentoEquipeImage,
  },
  {
    id: "8",
    titulo: "Programa de Desenvolvimento Pessoal",
    descricao:
      "Programa de treinamento e desenvolvimento, de acordo com os objetivos estratégicos da organização.",
    icon: PessoasImage,
  },
  {
    id: "9",
    titulo: "DESENVOLVIMENTO DE LIDERANÇAS",
    descricao: "Desenvolver nas lideranças, habilidades em gestão de pessoas. ",
    icon: LiderImage,
  },
  {
    id: "10",
    titulo: "NORMAS E PROCEDIMENTOS",
    descricao:
      "Criação de normas e procedimentos administrativos de gestão de pessoas.",
    icon: ProcedimentoImage,
  },
  {
    id: "11",
    titulo: "PERFIL PROFISSIONAL E COMPORTAMENTAL",
    descricao:
      "Desenvolvimento de perfil profissional e comportamental para cada cargo. ",
    icon: ComportamentoImage,
  },
  {
    id: "12",
    titulo: "CULTURA ORGANIZACIONAL",
    descricao: "Defender, propagar e alinhar a cultura organizacional.",
    icon: CulturaImage,
  },
  {
    id: "13",
    titulo: "CLIMA ORGANIZACIONAL",
    descricao: "Zelar pelo bom clima organizacional. ",
    icon: ClimaImage,
  },
  {
    id: "14",
    titulo: "RELACIONAMENTO INTERPESSOAL",
    descricao: "Reduzir conflitos entre colaboradores. ",
    icon: RelacaoPessoalImage,
  },
  {
    id: "15",
    titulo: "QUALIDADE DE VIDA",
    descricao:
      "Monitorar e assegurar melhor qualidade de vida dos envolvidos. ",
    icon: QualidadeImage,
  },
  {
    id: "16",
    titulo: "GESTÃO DE DESEMPENHO",
    descricao: "Aplicar gestão visando transformar potencial em desempenho.",
    icon: DesempenhoImage,
  },
  {
    id: "17",
    titulo: "MOTIVAÇÃO",
    descricao: "Motivar Colaboradores para gerar melhores resultados.",
    icon: MotivacaoImage,
  },
];
