import imagem1 from "../../assets/svg-1.svg";
import imagem2 from "../../assets/svg-2.svg";
import imagem3 from "../../assets/svg-3.svg";
import ambienteImage from "../../assets/ambiente.svg";
import fogueteImage from "../../assets/futuro.svg";
import experienciaImage from "../../assets/experiencia.svg";
import workingImage from "../../assets/working.svg";
import profissionalImage from "../../assets/profissional.svg";

export const dadosInfoSections = [
  {
    id: "vantagens",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topline: "Vantagens",
    headline: "A terceirização do RH é uma vantagem",
    description: [
      {
        desc: "As organizações são diariamente assombradas pela alta tributação dos impostos, custos elevados na contração de pessoal, alto turnover, falta de comprometimento, e por vezes, baixíssimo engajamento das pessoas com os interesses da empresa. Um custo bastante elevado, que compromete os resultados finais. ",
      },
      {
        desc: "Quando os serviços são terceirizados a empresa tende a se concentrar mais no seu negócio, principalmente, no lançamento de novos produtos e serviços, no aumento dos clientes e na fidelização daqueles já existentes.",
      },
      {
        desc: "Agora imagine ter uma equipe de especialistas em gestão de pessoas por um valor acessível à sua realidade? Terceirizar é uma vantagem competitiva.",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: true,
    img: workingImage,
    alt: "Car",
    dark: true,
    primary: false,
    darkText: true,
    displayVantagensComponents: true,
  },
  {
    id: "objetivo",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topline: "Harmonia",
    headline: "O ambiente de trabalho é fundamental.",
    description: [
      {
        desc: "Nosso desejo é transformar a sua empresa em um ótimo lugar para se trabalhar, onde as pessoas encontrem prazer e satisfação em pertencer a equipe, em construir uma carreira e juntos celebrar as vitórias.",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: false,
    img: ambienteImage,
    alt: "Car",
    dark: true,
    primary: false,
    darkText: false,
    displayVantagensComponents: false,
  },
  {
    id: "objetivo",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topline: "Evolução",
    headline: "O futuro começa aqui",
    description: [
      {
        desc: "O visionário é aquele que não fica paralizado nos problemas, nas dificuldades, muito pelo contrário, ele é capaz de perceber possibilidades na frente dos seus concorrentes. Por isso, se destaca, cresce e se torna uma referência.",
      },
      {
        desc: "O futuro precisa ser semeado todos os dias. Não se colhe algo que não se tenha plantado. É a lei natural das coisas. ",
      },
      {
        desc: "Parar por alguns instantes e olhar sem medo e com sinceridade para o negócio, ajuda a perceber as mazelas, as inconformidades, mas acima de tudo, significa sair da zona de conforto e iluminar tudo aquilo que está na penumbra, nas sombras. A luz possibilita clarear o ambiente, as ideias e tornar nítido o que estava oculto, para daí buscar soluções, parceiros, saídas eficazes que irão melhorar a performance dos serviços e a qualidade dos produtos.",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: true,
    img: fogueteImage,
    alt: "Car",
    dark: false,
    primary: false,
    darkText: true,
    displayVantagensComponents: false,
  },
  {
    id: "objetivo",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topline: "Humanidade",
    headline: "A experiência faz toda diferença",
    description: [
      {
        desc: "Competência significa que uma pessoa tem conhecimentos, habilidades e atitudes sobre determinado assunto.",
      },
      {
        desc: "A experiência é quando a competência é exercida ao longo do tempo; meses, anos dedicados a fazer, refazer, errar e acertar, refinar e melhorar. Essa prática ajuda a encurtar o caminho do sucesso, eliminar o que não deu certo, ajuda a traçar uma metodologia para chegar mais rápido ao lugar desejado.",
      },
      {
        desc: "A Heroup conta com profissionais com forte experiência no mercado. Sólida formação acadêmica e profissional, alta capacidade para transformar equipes medíocres em equipes de alta performance. ",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: false,
    img: experienciaImage,
    alt: "Car",
    dark: true,
    primary: false,
    darkText: false,
    displayVantagensComponents: false,
  },
  {
    id: "sobre",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topline: "Sobre a Heroup",
    headline: "Mais de 30 anos despertando pessoas",
    description: [
      {
        desc: "É uma instituição especializada no desenvolvimento humano e na gestão de pessoas. Fundada em 2020, com profissionais capacitados com mais de 30 anos de experiência. Com atendimento em todo Brasil, busca soluções inteligentes visando atender necessidades pontuais dos clientes.",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: true,
    img: imagem1,
    alt: "Car",
    dark: true,
    primary: false,
    darkText: true,
    displayVantagensComponents: false,
  },
  {
    id: "objetivo",
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    topline: "Desenvolvimento",
    headline: "Soft Skills e Hard Skills",
    description: [
      {
        desc: "Acreditamos que  o melhor caminho sempre será o desenvolvimento humano. Portanto, para a empresa gerar os melhores resultados é imperativo desenvolver as pessoas em suas habilidades, comportamentais e técnicas, alinhando-as com os objetivos estratégicos.",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: false,
    img: imagem2,
    alt: "piggy",
    dark: true,
    primary: false,
    darkText: false,
    displayVantagensComponents: false,
  },
  {
    id: "time",
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topline: "Nosso Time",
    headline: "A tecnologia no engajamento de pessoas",
    description: [
      {
        desc: "A Heroup desenvolve sistemas interno específicos que contribuem para melhorar a administração dos negócios. Contamos com uma equipe com engenheiros de softwares altamente capacitados e experientes para atender e gerar soluções para os nossos parceiros.",
      },
      {
        desc: "A tecnologia é uma ferramenta utilizada pela Heroup de forma assertiva para gerar os melhores resultados para o cliente, que aplicada pelas pessoas certas possibilita melhorar significativamente os resultados da empresa. ",
      },
    ],
    buttonLabel: "Entre em contato",
    imgStart: false,
    img: imagem3,
    alt: "Paper",
    dark: false,
    primary: false,
    darkText: true,
    displayVantagensComponents: false,
  },
  {
    id: "sobre",
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    topline: "Sobre a Heroup",
    headline: "Engajar pessoas faz acontecer",
    description: [
      {
        desc: "A Heroup é uma instituição especializada na assessoria, gestão e administração de negócios.",
      },
      {
        desc: "Tem como missão ajudar as empresas e as pessoas a seguirem para o próximo nível de evolução. Melhorar os resultados dos negócios aplicando estratégias para engajar pessoas.",
      },
      {
        desc: "Com atuação em todo Brasil busca soluções inteligentes para atender as necessidades pontuais dos parceiros. Para Heroup a sua empresa é o nosso negócio.",
      },
    ],
    imgStart: true,
    buttonLabel: "Entre em contato",
    img: profissionalImage,
    alt: "Car",
    dark: true,
    primary: false,
    darkText: false,
    displayVantagensComponents: false,
  },
];
