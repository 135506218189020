import styled from "styled-components";
import { cores } from "../../themes/colors";

export const Titulo = styled.h1`
  font-size: 2.5rem;
  color: ${({ black }) => (black ? cores.darkText : cores.lightText)};
  margin: 64px 0;

  @media screen and (max-width: 468px) {
    font-size: 2rem;
  }
`;
