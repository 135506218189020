import React, { useState } from "react";
import SideBar from "../components/Sidebar";
import NavBar from "../components/NavBar";
import MainComponent from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import { dadosInfoSections } from "../components/InfoSection/data";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Planos, { BoxTitulo } from "../components/Plano";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <MainComponent />
      {dadosInfoSections.map((item, key) => {
        return <InfoSection key={key} {...item} />;
      })}
      <Services />
      <BoxTitulo />
      <Planos />
      <Footer />
    </>
  );
};

export default Home;
