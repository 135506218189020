import styled from "styled-components";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

export const ServicesContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #161322;
  padding: 40px 0;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  @media screen and (max-width: 468px) {
    height: auto;
  }
`;

export const ContainerWrapper = styled.div`
  min-width: 1100px;
  max-width: 1100px;
  padding: 0 24px;
  margin-bottom: 64px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    min-width: 800px;
    max-width: 800px;
  }
`;

export const Carrossel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  width: ${({ width }) => `${width}px`};
  transition: all ease-in-out 0.3s;
  margin-left: ${({ margin }) => `${margin}px`};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const ArrowLeft = styled(AiOutlineArrowLeft)`
  color: #fff;
  font-size: 2em;
  min-height: 360px;
  background-color: #161322;
  position: absolute;
  z-index: 9;
  width: 50px;
  top: 50%;
  left: 35px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: #0e0e0e;
  transition: all ease-in-out 0.3s;
  filter: ${({ hover }) => (hover ? "opacity(0.5)" : "opacity(0)")};

  :hover {
    filter: opacity(0.5);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ArrowRight = styled(AiOutlineArrowRight)`
  color: #fff;
  font-size: 2em;
  min-height: 360px;
  position: absolute;
  z-index: 9;
  width: 50px;
  top: 50%;
  right: -25px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: #161322;
  transition: all ease-in-out 0.3s;
  filter: ${({ hover }) => (hover ? "opacity(0.5)" : "opacity(0)")};

  :hover {
    filter: opacity(0.5);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
