import { BoxCard, ServicesContainer, ServicesH1 } from "./styles";
import { infoVantagens } from "./data";
import CardVantagens from "../CardVantagem/index.js";

export default function Vantagens({ displayVantagensComponents }) {
  return (
    <ServicesContainer display={displayVantagensComponents}>
      <ServicesH1>As vantagens de terceirizar seu RH</ServicesH1>
      <BoxCard>
        {infoVantagens.map((card) => {
          return <CardVantagens key={card.id} {...card} />;
        })}
      </BoxCard>
    </ServicesContainer>
  );
}
