import React, { useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  Carrossel,
  ContainerWrapper,
  ServicesContainer,
} from "./styles";
import { infoServicos } from "./infoServicos";
import CardServico from "../CardService";
import { Titulo } from "../Titulo";

const Services = () => {
  const [margin, setMargin] = useState(0);
  const [hover, setHover] = useState(false);

  const mouseEnter = () => setHover(true);
  const mouseLeave = () => setHover(false);

  const rightButton = () => {
    const tamanhoConteudo = 290;
    const width = infoServicos.length * tamanhoConteudo;
    if (margin > -width) {
      const novaMargin = margin - 930;
      setMargin(novaMargin);
    }
  };

  const leftButton = () => {
    if (margin !== 0) {
      const novaMargin = margin + 930;
      setMargin(novaMargin);
    }
  };

  return (
    <>
      <ServicesContainer id="services">
        <Titulo>Nossos Serviços</Titulo>
        <ContainerWrapper>
          <ArrowLeft onClick={leftButton} hover={hover ? 1 : 0} />
          <ArrowRight onClick={rightButton} hover={hover ? 1 : 0} />
          <Carrossel
            width={infoServicos.length * 310}
            margin={margin}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            {infoServicos.map((item) => {
              return <CardServico key={item.id} {...item} />;
            })}
          </Carrossel>
        </ContainerWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
